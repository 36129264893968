<template>
    <SupplierProductPage product_router="EndUserGiftCardProduct" page_title="Gift card" user_type="enduser"></SupplierProductPage>
</template>
  
<script>
import SupplierProductPage from '@/components/endUser/suppliersPages/SupplierProductPage.vue';

export default {
    name: 'EndUserGiftCardProduct',
    components: {
        SupplierProductPage
    },
}
</script>

